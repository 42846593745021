import React, { useEffect, useState } from "react";
import SimCardOutlinedIcon from '@mui/icons-material/SimCardOutlined';

export default function SimData({ simNumber }) {
  const [jwtToken, setJwtToken] = useState(null);
  const [simData, setSimData] = useState(null);
  const [error, setError] = useState(null);

  const handleLogin = async () => {
    try {
      const loginResponse = await fetch(
        `${process.env.REACT_APP_MGCONNECT_PELEPHONE_LOGIN}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ username: "nadav", password: "Nqc9MRki2@M2wbu" }),
        }
      );

      if (!loginResponse.ok) {
        throw new Error(`Login failed with status: ${loginResponse.status}`);
      }

      const loginData = await loginResponse.json();
      // console.log("Login response:", loginData);

      if (loginData.result !== "SUCCESS") {
        throw new Error("Login was not successful");
      }

      setJwtToken(loginData.jwtToken);
      setError(null);
      fetchSimData();
    } catch (err) {
      console.error("Error:", err);
      setError(err.message);
    }
  };

  const fetchSimData = async () => {
    // if (!jwtToken) {
    //   setError("Please log in first");
    //   return;
    // }
    
    try {
      const simResponse = await fetch(
        `${process.env.REACT_APP_MGCONNECT_GET_SIM_DATA}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({
            username: "nadav",
            password: "Nqc9MRki2@M2wbu",
            limit: 1,
            search: [
              {
                fieldName: "SIM_SWAP",
                fieldValue: simNumber,
              },
            ],
          }),
        }
      );

      if (!simResponse.ok) {
        throw new Error(`Fetching SIM data failed with status: ${simResponse.status}`);
      }

      const simData = await simResponse.json();
      // console.log("SIM data:", simData);
      setSimData(simData);
      setError(null);
    } catch (err) {
      console.error("Error:", err);
      setError(err.message);
    }
  };


  useEffect(() => {
    handleLogin();
  },[simNumber]);

  return (
    <div>
      {error && <p style={{ color: "red" }}>Error: {error}</p>}
      {simData && simData.data && simData.data.length > 0 ? (
        <div>
          <h6><SimCardOutlinedIcon/> SIM information:</h6>
          <h6 style={{fontWeight:"bolder"}}> Sim Number: <span style={{fontWeight:"lighter"}}>{simNumber || 'N/A'}</span></h6>
          <h6 style={{fontWeight:"bolder"}}> CLI: <span style={{fontWeight:"lighter"}}>{simData.data[0].CLI || 'N/A'}</span></h6>
          <h6 style={{fontWeight:"bolder"}}> Last Session Time: <span style={{fontWeight:"lighter"}}>{simData.data[0].LAST_SESSION_TIME || 'N/A'}</span></h6>
          <h6 style={{fontWeight:"bolder"}}> Monthly Usage (MB): <span style={{fontWeight:"lighter"}}>{simData.data[0].MONTHLY_USAGE_MB || 'N/A'}</span></h6>
          <h6 style={{fontWeight:"bolder"}}> Bundle Utilization: <span style={{fontWeight:"lighter"}}>{simData.data[0].BUNDLE_UTILIZATION || 'N/A'}</span></h6>
          <h6 style={{fontWeight:"bolder"}}> Allocated (MB): <span style={{fontWeight:"lighter"}}>{simData.data[0].ALLOCATED_MB || 'N/A'}</span></h6>
        </div>
      ) : (<p>No data available</p>)}
    </div>
  );
}
