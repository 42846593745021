import React, { useState, useEffect } from 'react';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { APIProvider, Map, AdvancedMarker, } from '@vis.gl/react-google-maps';
import { Polyline } from './polyline.tsx';
import "../MgConnect/MgConnectGpsLocation.css";

export default function MgConnectGpsLocation({ lastGpsLocation = [], gpsLocations = [], eventSelectedLocation }) {
// This trigger when we want to disaply when ever the user insert new imei


  const [zoom, setZoom] = useState(5);
  const [center, setCenter] = useState({lat:32.0966275, lng: 34.8481448});
  
  const handleZoomChanged = (e) => {
    setZoom(e.map.zoom);
  };
  
  const handleCenterChanged = (e) => {
      setCenter(e.detail.center);
  };

  const moveToPosition = (location) => {
    if (location && location.lat !== undefined && location.lng !== undefined) {
      if (location.lat === 0 && location.lng === 0) {
        setCenter({ lat: 32.0966275, lng: 34.8481448 });
        setZoom(5);
      } else {
        setCenter({ lat: location.lat, lng: location.lng });
        setZoom(16);
      }
    }
  };

    //Here we need to cast the original array to array with lat and lng only for the Polyline path={} obj to work 
  const validLocations = gpsLocations
    .filter((loc) => loc.Lat !== undefined && loc.Lng !== undefined) // Ensure Lat and Lng exist
    .map((loc) => ({ lat: loc.Lat, lng: loc.Lng })) // Convert to { lat, lng }
    .filter((coord) => coord.lat !== 0 && coord.lng !== 0); //If the lat or the lng are 0 then ignore it


 

  // Move to last device position when lastGpsLocation changes
  useEffect(() => {
   moveToPosition(lastGpsLocation ? { lat: lastGpsLocation.Lat, lng: lastGpsLocation.Lng } : null);
  }, [lastGpsLocation]);

// Move to the first valid GPS location when the list changes
  useEffect(() => {
    if (validLocations.length > 0) {
      moveToPosition(validLocations[0]);
    }
  }, [validLocations.length]);

  // Move to event-selected location
  useEffect(() => {
    moveToPosition(eventSelectedLocation);
  }, [eventSelectedLocation]);

    
    return (
    <div className='deviceGpsLocationSection'>
      <h4 className='deviceGpsLocationHeader'><LocationOnOutlinedIcon/> GPS Location</h4>
      {lastGpsLocation?.Lat !== undefined && lastGpsLocation?.Lng !== undefined ? (
        <div>
        <h6 style={{gap: "10px", margin: "20px" }}>Last Known Position: <span style={{fontWeight:"lighter"}}>&nbsp;&nbsp;Lat: {lastGpsLocation?.Lat} , Lng: {lastGpsLocation?.Lng}</span></h6>
        <div className='deviceGpsLocationMapContainer'>
          <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <Map
             mapId={"a1530c1f49c95f3a"}
             style={{width:"100%", height:"78%"}}
             reuseMaps={true}
             zoom={zoom}
             center={center}
             cameraControl={false}
             clickableIcons={false}
             streetViewControl={false}
             zoomControl= {true}
             rotateControl={true}
             overviewMapControl={false}
             scaleControl={true}
             mapTypeControl={false}
             keyboardShortcuts={false}
             onBoundsChanged={(e) => handleCenterChanged(e)}
             onZoomChanged={(e) => handleZoomChanged(e)}
            >

          {lastGpsLocation?.Lat !== undefined && lastGpsLocation?.Lng !== undefined && 
            <AdvancedMarker position={{lat:lastGpsLocation.Lat, lng:lastGpsLocation.Lng}} clickable={false} draggable={false}/>
          }

          {validLocations.length !== 0 &&
            <>
              <AdvancedMarker position={{lat:validLocations[0]?.lat, lng: validLocations[0]?.lng}} clickable={false} draggable={false}>
                <img alt='lastPositionIcon' src={'./lastPositionIcon.png'}/>
              </AdvancedMarker>
              <Polyline path={validLocations} visible={true}strokeColor={'#AA00FF'}/>
              <AdvancedMarker  position={{lat:validLocations[validLocations.length-1]?.lat, lng:validLocations[validLocations.length-1]?.lng}} clickable={false} draggable={false}>
                <img alt='startPositionIcon' src={'./startPositionIcon.png'}/>
              </AdvancedMarker>
            </>
          } 

          {eventSelectedLocation !== null &&
            <AdvancedMarker position={{lat: eventSelectedLocation.lat, lng: eventSelectedLocation.lng}} clickable={false} draggable={false}>
              <span style={{color:"#001aff"}}>◉</span>
            </AdvancedMarker>
          }
                      
            </Map>
          </APIProvider>
        </div>
        </div>
        ) : (<p style={{textAlign:"center"}}>No device information available</p>)}
    </div>
  )
};
