import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const [isCommandInProcessing, setIsCommandInProcessing] = useState(false);

  return (
    <UserContext.Provider value={{ loggedIn, setLoggedIn, userName, setUserName, isCommandInProcessing, setIsCommandInProcessing }}>
      {children}
    </UserContext.Provider>
  );
};