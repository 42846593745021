import React, { useState, useEffect } from 'react';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import MemoryIcon from '@mui/icons-material/Memory';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import SpeedIcon from '@mui/icons-material/Speed';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
// import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import "../MgConnect/MgConnectDeviceInformation.css";
import ConvertDate from './ConvertDate';
import SimData from './SimData';

export default function MgConnectDeviceInformation({ deviceInformationData = [], deviceLastCommunicationAndGpsData = [], simData = [] }) {
  const [timeDiff, setTimeDiff] = useState(null);

  useEffect(() => {
    const calculateTimeDiff = () => {
      if (deviceLastCommunicationAndGpsData.LastCommunication?.Timestamp) {
        const lastTimestamp = deviceLastCommunicationAndGpsData.LastCommunication.Timestamp * 1000; // Convert to milliseconds
        const now = Date.now();
        const diffMs = now - lastTimestamp;

        const hours = Math.floor(diffMs / (1000 * 60 * 60));
        const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

        setTimeDiff(`${hours} hours ${minutes} min ago`);
      }
    };

    // Initial calculation
    calculateTimeDiff();

    // Update every minute
    const interval = setInterval(calculateTimeDiff, 60000);

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [deviceLastCommunicationAndGpsData]);
  
  return (
    <div className='deviceInformationSection'>
      <h4 className='deviceInformationHeader'><InfoOutlinedIcon/> Device Information</h4>
      <div className='deviceInformationContainer'>
        {deviceInformationData.length !== 0 ? (
            <div>
              <h6><Battery0BarIcon/> Car Battery: <span style={{fontWeight:"lighter"}}>{deviceInformationData.Status?.Battery?.Level / 1000 || 'N/A'} V</span></h6>
              <h6 style={{fontWeight:"bolder"}}><MemoryIcon/> SW Version: <span style={{fontWeight:"lighter"}}>{deviceInformationData.SwVersion || 'N/A'}</span></h6>
              <h6 style={{fontWeight:"bolder"}}><Grid3x3Icon/> HW Version: <span style={{fontWeight:"lighter"}}>{deviceInformationData.HwVersion || 'N/A'}</span></h6>
              <h6 style={{fontWeight:"bolder"}}><SpeedIcon/> Odometer: <span style={{fontWeight:"lighter"}}>{deviceInformationData.Status?.Odometer || 'N/A'} km</span></h6>
              {/* <h6 style={{fontWeight:"bolder"}}><CandlestickChartOutlinedIcon/> CAN Date: <span style={{fontWeight:"lighter"}}>Engine RPM: 2500, Speed: 60km/h</span></h6> */}
              <h6 style={{ fontWeight:"bolder"}}><AccessTimeIcon /> Last Communication: <span style={{ fontWeight: "lighter", }}>{ConvertDate(deviceLastCommunicationAndGpsData.LastCommunication?.Timestamp) ?? 'N/A'}</span></h6>
              <h6><span>Time Since Last Communication: {timeDiff || 'N/A'}</span></h6>
              <SimData simNumber={deviceInformationData.SimID}/>
              {/* <h6 style={{ fontWeight: "bolder" }}><LocationOnOutlinedIcon /> Last GPS Update: <span style={{ fontWeight: "lighter", }}>{ConvertDate(deviceLastCommunicationAndGpsData.LastValidGps?.Timestamp) ?? 'N/A'}</span></h6> */}
            </div>
          ) : ( <p style={{textAlign:"center"}}>No device information available</p> )}
      </div>
    </div>
  )
};
