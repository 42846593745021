import React from 'react';
import { Menu } from 'antd';

const JeneMenu = ({ currentMenuItem, handleMenuClick }) => {
  const items = [
    { label: 'Create Rule+', key: 'createRule' },
    { label: 'Model', key: 'model' },
    { label: 'IMEI', key: 'imei' },
    { label: 'Unit History ⟲', key: 'unitHistory' },
    { label: 'Statistics 📊', key: 'statistics' },
    { label: 'Sim Usage 📈', key: 'simUsage' },
  ];

  return (
    <Menu
      onClick={handleMenuClick}
      selectedKeys={[currentMenuItem]}
      mode="horizontal"
      items={items}
      style={{ justifyContent: 'center' }}
    />
  );
};

export default JeneMenu;
