import React, { useState } from 'react';
import { message } from 'antd';
import MgConnectMenu from '../Components/MgConnect/MgConnectMenu';
import MgConnectStatistics from '../Components/MgConnect/MgConnectStatistics';
import MgConnectDeviceCommand from '../Components/MgConnect/MgConnectDeviceCommand';
import MgConnectDeviceInformation from '../Components/MgConnect/MgConnectDeviceInformation';
import MgConnectGpsLocation from '../Components/MgConnect/MgConnectGpsLocation';
import MgConnectDeviceConfiguration from '../Components/MgConnect/MgConnectDeviceConfiguration';
import MgConnectMessageLog from '../Components/MgConnect/MgConnectMessageLog';
import MgConnectDeviceSelection from '../Components/MgConnect/MgConnectDeviceSelection';
import axios from 'axios';
import './MgConnect.css';

export default function MgConnect() {
  const [currentMenuItem, setCurrentMenuItem] = useState('monitor');
  const [imeiToSearch, setImeiToSearch] = useState('');
  const [deviceInformationData, setDeviceInformationData] = useState([]);
  const [deviceLastCommunicationAndGpsData, setDeviceLastCommunicationAndGpsData] = useState([]);
  const [deviceGpsLastLocationData, setDeviceGpsLastLocationData] = useState([]);
  const [deviceConfigurationData, setDeviceConfigurationData] = useState([]);
  const [deviceMessageLogData, setDeviceMessageLogData] = useState([]);
  //This will hold special data with range of time
  const [deviceLocationsData, setDeviceLocationsData] = useState([]);
  const [eventSelectedLocation, setEventSelectedLocation] = useState(null);

 
  const onFinish = async (values) => {
    const imei = values.requiredImei.trim();
    if (!containsOnlyDigits(imei)) {
      message.error('The IMEI should only contain numbers');
      return;
    }
    clearData();
    const exists = await iSImeiExist(imei);
    if (exists) {
      setImeiToSearch(imei); // Only update IMEI once, after checking existence
      fetchDeviceInformation(imei);
      fetchDeviceLastCommunicationAndGps(imei);
      fetchDeviceConfiguration(imei);
      fetchGpsLastLocation(imei);
      fetchMessageLog(imei);
    } else {
      message.error(`Error: No data found for ${imei}`, 2);
    }
  };

  const checkDevice = async (imei) => {
    const result = await iSImeiExist(imei);
    //if TRUE fecth all the data with this imei
    if (result) {
      // console.log(`Fetching data for ${imei}`);
      fetchDeviceInformation(imei);
      fetchDeviceLastCommunicationAndGps(imei);
      fetchDeviceConfiguration(imei);
      fetchGpsLastLocation(imei);
      fetchMessageLog(imei);
      return;
    } else {
      setImeiToSearch("");
      clearData();
      console.log("IMEI does not exist");
      message.error(`Error: No data found for ${imei}`, 2);
      return;
    }
  };
  
 
  const iSImeiExist = async (imei) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MGCONNECT_CHECK_IF_DEVICE_EXIST}${imei}`);
      if (response.status === 200) {
        setImeiToSearch(imei);
        return true; // Return true when status is 200
      }
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status === 404) {
          setImeiToSearch("");
          return false; // Explicitly return false for 404
        } else if (status === 500) {
          setImeiToSearch("");
          message.error("Server not responding", 2);
          return false; // Explicitly return false for 500
        }
      }
      console.error("Error in iSImeiExist:", error);
      message.error("An unexpected error occurred", 2);
      return false; // Return false for unexpected errors
    }
  };
  
  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
    setImeiToSearch('');
    setDeviceInformationData([]);
  };

  //***********************START of feching data section*********************IN FUTURE NEED TO MAKE THIS CUSTOM HOOK

  const fetchDeviceInformation = async (imei) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MGCONNECT_UNIVERSAL_API}${imei}&${process.env.REACT_APP_MGCONNECT_TYPE_INFO}&NthLast=1`);
      setDeviceInformationData(response.data);
    } catch (error) {
      setImeiToSearch("");
      console.error(error);
      message.error(`Error: No data has found for ${imei}`, 2);
    }
  };

  const fetchDeviceLastCommunicationAndGps = async (imei) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MGCONNECT_LAST_COMMUNICATION_AND_GPS}${imei}`);
      setDeviceLastCommunicationAndGpsData(response.data);
    } catch (error) {
      setImeiToSearch("");
      console.error(error);
      message.error(`Error: No data has found for ${imei}`, 2);
    }
  };

  
  const fetchGpsLastLocation = async (imei) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MGCONNECT_UNIVERSAL_API}${imei}&${process.env.REACT_APP_MGCONNECT_TYPE_GPS}&NthLast=1`);
      setDeviceGpsLastLocationData(response.data);
    } catch (error) {
      console.error(error);
      message.error('Failed to fetch GPS location');
    }
  };
  
  const fetchDeviceConfiguration = async (imei) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MGCONNECT_UNIVERSAL_API}${imei}&${process.env.REACT_APP_MGCONNECT_TYPE_SYSTEM_CONFIG}&NthLast=1`);
      setDeviceConfigurationData(response.data);
    } catch (error) {
      console.error(error);
      message.error('Failed to fetch device configuration');
    }
  };
  
  const fetchMessageLog = async (imei) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MGCONNECT_UNIVERSAL_API}${imei}&${process.env.REACT_APP_MGCONNECT_TYPE_ALL}&StartTime=${Math.floor((Date.now() - 24 * 60 * 60 * 1000) / 1000)}&EndTime=${Math.floor(Date.now() / 1000)}`);
      setDeviceMessageLogData(response.data);
    } catch (error) {
      console.error(error);
      message.error('Failed to fetch message log');
    }
  };

  //**************************************END of feching data**********************************
  

  const handleDateRangeSearch = async(chosenDate) => {
    // console.log(`Fetching data between ${chosenDate[0]} and ${chosenDate[1]}`);
    const startTimestamp = chosenDate[0];
    const endTimestamp = chosenDate[1];
    try {
      const messagesLogsResponse = await axios.get(`${process.env.REACT_APP_MGCONNECT_UNIVERSAL_API}${imeiToSearch}&${process.env.REACT_APP_MGCONNECT_TYPE_ALL}&StartTime=${startTimestamp}&EndTime=${endTimestamp}&NthLast=0`);
      setDeviceMessageLogData(messagesLogsResponse.data);
      const gpsLocationsResponse = await axios.get(`${process.env.REACT_APP_MGCONNECT_UNIVERSAL_API}${imeiToSearch}&${process.env.REACT_APP_MGCONNECT_TYPE_GPS}&StartTime=${startTimestamp}&EndTime=${endTimestamp}&NthLast=0`);
      setDeviceLocationsData(gpsLocationsResponse.data);
    } catch (error) {
      console.log(error);
      message.error('Failed to get data with this date range');
    }    
  };

  const containsOnlyDigits = (str) => {
    return /^\d+$/.test(str);
  };

  const handleSetEventLocation = (lat, lng) => {
    if (lat !== null && lng !== null) {
      setEventSelectedLocation({ lat, lng });
    }
  };

  const clearData = () => {
    setImeiToSearch(''); 
    setDeviceInformationData([]);
    setDeviceLastCommunicationAndGpsData([]);
    setDeviceGpsLastLocationData([]);
    setDeviceConfigurationData([]);
    setDeviceMessageLogData([]);
    setDeviceLocationsData([]);
  };

  const handleMenuClick = (e) => {
    setCurrentMenuItem(e.key);
    if (currentMenuItem === 'monitor'){
    }
    if (currentMenuItem === 'statistics'){
      // setImeiToSearch("");
      // clearData();
    }
  };

  return (
    <div>
    <MgConnectMenu currentMenuItem={currentMenuItem} handleMenuClick={(e) => handleMenuClick(e)}/>

    {currentMenuItem === "monitor" && (
      <>
        <div className='deviceSelectionAndCommandContainer'>
          <MgConnectDeviceSelection imeiToSearch={imeiToSearch} onFinish={onFinish} onFinishFailed={onFinishFailed} clearData={clearData}/>
          <MgConnectDeviceCommand chosenImei={imeiToSearch} fetchDeviceInformation={fetchDeviceInformation} fetchDeviceLastCommunicationAndGps={fetchDeviceLastCommunicationAndGps} fetchDeviceConfiguration={fetchDeviceConfiguration}/>
        </div>
        <div className="deviceInformationAndGpsLocationAndConfigurationContainer">
          <div className="leftContainer">
            <MgConnectDeviceInformation deviceInformationData={deviceInformationData} deviceLastCommunicationAndGpsData={deviceLastCommunicationAndGpsData}/>
            <MgConnectDeviceConfiguration configurationData={deviceConfigurationData}/>
          </div>
          <div className="rightContainer">
            <MgConnectGpsLocation lastGpsLocation={deviceGpsLastLocationData} gpsLocations={deviceLocationsData} eventSelectedLocation={eventSelectedLocation}/>
          </div>
        </div>
        <MgConnectMessageLog onSearch={handleDateRangeSearch} messageLogData={deviceMessageLogData} onEventLocationSelect={handleSetEventLocation}/>
      </>
    )}

    {currentMenuItem === "statistics" && (
      <>
        <MgConnectStatistics/>
      </>
    )}
    </div>
  );
};
