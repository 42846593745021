import React, { useState, useContext } from 'react';
import { UserContext } from '../UserContext';
import { Button, message, Spin } from 'antd';
import { DownloadOutlined, SendOutlined, PoweroffOutlined, SettingOutlined } from '@ant-design/icons';
import TerminalIcon from '@mui/icons-material/Terminal';
import "../MgConnect/MgConnectDeviceCommand.css";
import axios from 'axios';

export default function MgConnectDeviceCommand({ chosenImei, fetchDeviceInformation, fetchDeviceLastCommunicationAndGps, fetchDeviceConfiguration }) {
    const [messageApi, contextHolder] = message.useMessage();
    const { isCommandInProcessing, setIsCommandInProcessing } = useContext(UserContext);

    const handleCommand = async (action, url, params) => {
        setIsCommandInProcessing(true); // Disable all buttons
        const hide = messageApi.open({
            type: 'loading',
            content: `${action} in progress...`,
            duration: 0, // Keep it open until manually closed
        });

        try {
            const response = await axios.post(url, params);
            const { DiagnosticMessage, DiagnosticCode } = response.data || response;

            // Close the loading message and show success or error
            hide();
            if (DiagnosticCode === 0) {
                message.success(DiagnosticMessage);
                if (action === "Get Latest Data") {
                    setTimeout(() => {
                        fetchDeviceInformation(chosenImei);
                        fetchDeviceLastCommunicationAndGps(chosenImei);
                        message.success("Device Information updated");
                    }, 3000);
                }
                if (action === "Get Config") {
                    setTimeout(() => {
                        fetchDeviceConfiguration(chosenImei);
                        message.success("Device Configuration updated");
                    }, 3000);
                }
            } else {
                message.error(DiagnosticMessage);
            }
        } catch (error) {
            hide();
            message.error(error.response?.data?.DiagnosticMessage || 'An error occurred. Please try again.');
        } finally {
            setIsCommandInProcessing(false); // Enable all buttons
        }
    };

    const pingDevice = () => {
        handleCommand('Ping Device', `${process.env.REACT_APP_MGCONNECT_UNIVERSAL_COMMAND}`, { DeviceId: chosenImei, Command: `${process.env.REACT_APP_MGCONNECT_PING_COMMAND}` });
    };

    const getLatestData = () => {
        handleCommand('Get Latest Data', `${process.env.REACT_APP_MGCONNECT_UNIVERSAL_COMMAND}`, { DeviceId: chosenImei, Command: `${process.env.REACT_APP_MGCONNECT_LATEST_DATA_COMMAND}` });
    };

    const getConfig = () => {
        handleCommand('Get Config', `${process.env.REACT_APP_MGCONNECT_UNIVERSAL_COMMAND}`, { DeviceId: chosenImei, Command: `${process.env.REACT_APP_MGCONNECT_SYSTEM_CONFIG_COMMAND}` });
    };

    const resetDevice = () => {
        handleCommand('Reset Device', `${process.env.REACT_APP_MGCONNECT_UNIVERSAL_COMMAND}`, { DeviceId: chosenImei, Command: `${process.env.REACT_APP_MGCONNECT_RESTART_COMMAND}` });
    };

    const buttonStyle = (color) => ({
        backgroundColor: isCommandInProcessing ? 'grey' : color,
        color: 'white',
        border: 'none',
    });

    return (
        <div className="deviceCommandSection">
            {contextHolder}
            <h4 className="deviceCommandHeader"><TerminalIcon /> Device Commands</h4>
            <div className="deviceCommandButtonsSection">
                {isCommandInProcessing && <Spin style={{ alignContent: "center" }} />}
                <Button style={buttonStyle('#13C2C2')} disabled={!chosenImei || isCommandInProcessing} onClick={pingDevice}>
                    <SendOutlined /> Ping Device
                </Button>
                <Button style={buttonStyle('#13C2C2')} disabled={!chosenImei || isCommandInProcessing} onClick={getLatestData}>
                    <DownloadOutlined /> Get Latest Data
                </Button>
                <Button style={buttonStyle('#13C2C2')} disabled={!chosenImei || isCommandInProcessing} onClick={getConfig}>
                    <SettingOutlined /> Get Config
                </Button>
                <Button style={buttonStyle('red')} disabled={!chosenImei || isCommandInProcessing} onClick={resetDevice}>
                    <PoweroffOutlined /> Reset Device
                </Button>
            </div>
        </div>
    );
}
