import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, message,  } from 'antd';
import InfoCard from '../Components/Jene/InfoCard';
import JeneMenu from '../Components/Jene/JeneMenu';
import DownloadCsv from '../Components/Jene/DownloadCsv';
import DownloadJson from '../Components/Jene/DownloadJson';
import CreateFotaRule from '../Components/Jene/CreateFotaRule';
import SearchByModel from '../Components/Jene/SearchByModel';
import SearchByImei from '../Components/Jene/SearchByImei';
import UnitHistory from '../Components/Jene/UnitHistory';
import Statistics from '../Components/Jene/Statistics';
import SimInformation from '../Components/Jene/SimInformation';
import "./Jene.css";

export default function Jene() {
  const [currentMenuItem, setCurrentMenuItem] = useState('model');
  const [modelsNames, setModelsNames] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [imeiToSearch, setImeiToSearch] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    getModelsNames();
  }, []);

  const getModelsNames = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_MODELS_NAMES}`);
      setModelsNames(response.data.data);
      setSelectedModel("");
    } catch (error) {
      console.log(error);      
      setSelectedModel("");
    }
  };

  const handleSelectChange = async (value) => {
      setSelectedModel(value);
  };

  useEffect(() => {
    // console.log(data);
  }, [data]);

  const onFinish = async (values) => {
    if (containsOnlyDigits(values.requiredImei.trim()) || values.requiredImei === "ANY") {
      setImeiToSearch(values.requiredImei);
      setData([]);
      fetchData(values.requiredImei);
    } else {
      setImeiToSearch("");
      return message.error("The Imei should only contain numbers");
    }
  };

  const containsOnlyDigits = (str) => {
    return /^\d+$/.test(str);
  };

  const fetchData = async (imei) => {
    if (currentMenuItem === 'model'){
      if (selectedModel === null){
        message.error("Please select model");
        setData([]);
        return;
      }
    }
    try {
      const response = await axios.get((currentMenuItem === 'model') ? `${process.env.REACT_APP_MONGO_API_SERACH_BY_MODEL}${selectedModel}` : `${process.env.REACT_APP_MONGO_API}${imei}`);
      const responseData = response.data.data;
      const formattedData = Array.isArray(responseData) ? responseData : [responseData];
      setData(formattedData);
    } catch (error) {
      message.error(`Error: No data has found for ${imei}`, 2);
      console.clear();
      console.log("Error fetching data for:", imei);
      setSelectedModel("");
      setImeiToSearch("");
      setData([]);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    setImeiToSearch("");
    setData([]);
  };

  const downloadJSON = (fileName) => {
    if (data.length === 0) {
      return message.error("No data available for download!");
    }
    DownloadJson(fileName, data);
  };

  const downloadCsv = (fileName) => {
    if (data.length === 0) {
      return message.error("No data available for download!");
    }
    DownloadCsv(fileName, data);
  };

  const handleMenuClick = (e) => {
    getModelsNames();
    setCurrentMenuItem(e.key);
    if (currentMenuItem === 'imei'){
      setSelectedModel("");
    }
    if (currentMenuItem === 'model'){
      setImeiToSearch("");
    }
    if (currentMenuItem === 'createRule'){
      setImeiToSearch("");
      setSelectedModel("");
      setData([]);
    }
    if (currentMenuItem === 'unitHistory'){
      setImeiToSearch("");
      setSelectedModel("");
      setData([]);
    }
    if (currentMenuItem === 'statistics'){
      setImeiToSearch("");
      setSelectedModel("");
      setData([]);
    }
    if (currentMenuItem === 'simUsage'){
      setImeiToSearch("");
      setSelectedModel("");
      setData([]);
    }
  };

  return (
    <div>

      <JeneMenu currentMenuItem={currentMenuItem} handleMenuClick={handleMenuClick} />

      {currentMenuItem === 'createRule' && <CreateFotaRule/>}
      
      {currentMenuItem === 'model' && (
         <SearchByModel 
          modelsNames={modelsNames}
          selectedModel={selectedModel}
          onSelectChange={handleSelectChange}
          onRefresh={getModelsNames}
          onSearch={fetchData}
         />
      )}

      {currentMenuItem === 'imei' && (
        <SearchByImei 
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        />
      )}

      {(currentMenuItem === 'imei' || currentMenuItem === 'model') && (
        <>
          <div className='smallHeader'>
            <h6>Data for {currentMenuItem === 'imei' ? `IMEI: ${imeiToSearch}` : `MODEL: ${selectedModel}`}</h6>
          </div>
          <div>
            {data.map((info, index) => (
              <InfoCard data={info} key={info._id || index} fetchData={fetchData} imei={imeiToSearch} getModelsNames={getModelsNames}/>
            ))}
          </div>
          <div className='downloadButtonPostion'>
            <Button className='downloadJsonButton' onClick={() => downloadJSON(`${imeiToSearch}`)}>Download JSON</Button>
            <Button className='downloadCsvButton' onClick={() => downloadCsv(`${imeiToSearch}`)}>Download CSV</Button>
          </div>
        </>
      )}

      {currentMenuItem === 'unitHistory' && <UnitHistory/>}
      {currentMenuItem === 'statistics' && <Statistics/>}
      {currentMenuItem === 'simUsage' && <SimInformation/>}

    </div>
  );
};
