export default function convertDate(timestamp) {
  if (timestamp <= 0) {
    return "Not Valid Date";
  }

  // Convert timestamp from seconds to milliseconds
  const localDate = new Date(timestamp * 1000);

  // Extract components in the user's local timezone
  const day = localDate.getDate().toString().padStart(2, "0");
  const month = (localDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = localDate.getFullYear();
  const hours = localDate.getHours().toString().padStart(2, "0");
  const minutes = localDate.getMinutes().toString().padStart(2, "0");
  const seconds = localDate.getSeconds().toString().padStart(2, "0");

  // Return formatted date in the user's local timezone
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};
