import React, { useEffect, useState } from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import "../MgConnect/MgConnectDeviceConfiguration.css";

export default function MgConnectDeviceConfiguration({ configurationData = {} }) {
  const [deviceConfigurationData, setDeviceConfigurationData] = useState([]);

  // Utility to flatten nested objects
  const flattenObject = (obj, prefix = "") => {
    const result = {};
    for (const key of Object.keys(obj || {})) {
      const value = obj[key];
      const newKey = prefix ? `${prefix}.${key}` : key;
      if (value && typeof value === "object" && !Array.isArray(value)) {
        Object.assign(result, flattenObject(value, newKey));
      } else {
        result[newKey] = value;
      }
    }
    return result;
  };

  // Recalculate device configuration data when configurationData changes
  useEffect(() => {
    const flattenedData = flattenObject(configurationData);
    const updatedDeviceConfigurationData = Object.entries(flattenedData).map(
      ([key, value]) => ({
        parameter: key,
        value: value?.toString() || "",
      })
    );
    setDeviceConfigurationData(updatedDeviceConfigurationData);
  }, [configurationData]);

  return (
    <div className="deviceConfigurationSection">
      <h4 className="deviceConfigurationHeader"><SettingsOutlinedIcon /> Device Configuration</h4>
      {deviceConfigurationData.length !== 0 ? (
        <div>
          <div className="deviceConfigurationTableSection">
            <div className="table-wrapper">
              <table className="deviceConfigurationTable">
                <thead>
                  <tr>
                    <th className="table-header">PARAMETER</th>
                    <th className="table-header">VALUE</th>
                  </tr>
                </thead>
                <tbody>
                  {deviceConfigurationData.map((row, index) => (
                    <tr key={index} className="table-row">
                      <td className="table-cell">{row.parameter}</td>
                      <td className="table-cell">{row.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : ( <p style={{ textAlign: "center" }}>No device information available</p> )}
    </div>
  );
}
