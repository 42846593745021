import React, { useState } from "react";
import { Form, Input, Button, Card, message } from "antd";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import SimCardOutlinedIcon from '@mui/icons-material/SimCardOutlined';


export default function SimInformation() {
  const [form] = Form.useForm();
  const [simNumber, setSimNumber] = useState("");
  const [simData, setSimData] = useState(null);
  const [jwtToken, setJwtToken] = useState(null);
  const [error, setError] = useState(null);

  const getData = async (value) => {
    setSimNumber(value.requiredSimNumber);
    try {
      const loginResponse = await fetch(
        `${process.env.REACT_APP_MGCONNECT_PELEPHONE_LOGIN}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ username: "nadav", password: "Nqc9MRki2@M2wbu" }),
        }
      );

      if (!loginResponse.ok) {
        throw new Error(`Login failed with status: ${loginResponse.status}`);
      }

      const loginData = await loginResponse.json();
      if (loginData.result !== "SUCCESS") {
        throw new Error("Login was not successful");
      }

      setError(null);
      message.success("Login successful! Fetching SIM data...");

      // Ensure fetchSimData runs after state updates
      setJwtToken(loginData.jwtToken);
      fetchSimData(loginData.jwtToken, value.requiredSimNumber);
    } catch (err) {
      console.error("Error:", err);
      setError(err.message);
      message.error(`Error: ${err.message}`);
    }
  };

  const fetchSimData = async (token, simNumber) => {
    try {
      const simResponse = await fetch(
        `${process.env.REACT_APP_MGCONNECT_GET_SIM_DATA}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            username: "nadav",
            password: "Nqc9MRki2@M2wbu",
            limit: 1,
            search: [
              {
                fieldName: "SIM_SWAP",
                fieldValue: simNumber,
              },
            ],
          }),
        }
      );

      if (!simResponse.ok) {
        throw new Error(`Fetching SIM data failed with status: ${simResponse.status}`);
      }

      const simData = await simResponse.json();
      console.log("SIM data:", simData);
      setSimData(simData);
      setError(null);
      
      if (simData.data && simData.data.length > 0) {
        message.success("SIM data retrieved successfully!");
      } else {
        message.warning("No data found for this SIM number.");
      }
    } catch (err) {
      console.error("Error:", err);
      setError(err.message);
      message.error(`Error: ${err.message}`);
    }
  };

  const deleteInformation = () => {
    setSimData(null);
    setSimNumber("");
    form.resetFields();
  };

  const formatKey = (key) => {
    return key
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase() // Convert to lowercase
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
  };

  return (
    <>
      <div className="inputFormPosition">
        <h4 style={{ textAlign: "center" }}><SimCardOutlinedIcon/> SIM Usage</h4>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 800, width: "100%" }}
          autoComplete="off"
          onFinish={getData}
        >
          <Form.Item
            label="Insert SIM number"
            name="requiredSimNumber"
            rules={[{ required: true, message: "Please insert your SIM number" }]}
          >
            <div style={{ display: "flex" }}>
              <Input style={{ flex: 1 }} autoFocus />
              <Button type="primary" htmlType="submit" style={{ marginLeft: "8px" }}>
                Find <SearchOutlined />
              </Button>
              <Button
                style={{
                  borderColor: "#E74C3C",
                  backgroundColor: "#E74C3C",
                  color: "white",
                  marginLeft: "8px",
                }}
                onClick={deleteInformation}
              >
                Clear <ClearOutlined />
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>

      <div>
        {error && <p style={{ color: "red" }}>Error: {error}</p>}
        {simData && simData.data && simData.data.length > 0 ? (
          <Card title="SIM Information" bordered style={{ maxWidth: 600, margin: "20px auto" }}>
            {Object.entries(simData.data[0]).map(([key, value]) => (
              <p key={key}>
                <strong>{formatKey(key)}:</strong> {value || "N/A"}
              </p>
            ))}
          </Card>
        ) : (
          <p>No data available</p>
        )}
      </div>
    </>
  );
}
