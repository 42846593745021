export default function FormatTimeDifference(timestamp1, timestamp2) {
    if (!timestamp1 || !timestamp2) return "Invalid Time";

    // Ensure timestamps are numbers
    const time1 = new Date(timestamp1).getTime();
    const time2 = new Date(timestamp2).getTime();

    const diffInSeconds = Math.abs(Math.floor((time1 - time2) / 1000)); // Absolute difference in seconds

    const hours = Math.floor(diffInSeconds / 3600).toString().padStart(2, "0");
    const minutes = Math.floor((diffInSeconds % 3600) / 60).toString().padStart(2, "0");
    const seconds = (diffInSeconds % 60).toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
};
