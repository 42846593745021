import React from 'react';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import DateRange from '../DateRange';
import GpsFixedSharpIcon from '@mui/icons-material/GpsFixedSharp';
import { Button } from 'antd';
import ConvertDate from './ConvertDate';
import FormatTimeDifference from './FormatTimeDifference';
import "../MgConnect/MgConnectMessageLog.css";

export default function MgConnectMessageLog({ onSearch, messageLogData = [], onEventLocationSelect }) {

  const setEventLocationFromMessageLog = (tempLat, tempLng) => {
    if (tempLat !== null && tempLng !== null) {
      onEventLocationSelect(tempLat, tempLng);
    }
  };

  return (
    <div className='deviceMessageLog'>
      <h4 className='deviceMessageLogHeader'><AssignmentOutlinedIcon /> Message Log</h4>
      {Array.isArray(messageLogData) && messageLogData.length !== 0 ? (
      <div>
        <div className="dateRangeContainer">
          <DateRange onDateSearch={onSearch}/>
        </div>
        {messageLogData.length === 1 &&
          <p style={{textAlign:"center"}}>Data from last 24 hours</p>
        }
        <div className='deviceMessageLogContainer'>
          <div className='deviceMessageLogTableWrapper'>
            <table className='deviceMessageLogTable'>
              <thead>
                <tr>
                  <th scope="col" className="table-header">Index</th>
                  <th scope="col" className="table-header">EVENT TIME</th>
                  <th scope="col" className="table-header">RECEIVE TIME</th>
                  <th scope="col" className="table-header">DATA</th>
                  <th scope="col" className="table-header">Coordinates</th>
                </tr>
              </thead>
              <tbody>
                {messageLogData?.map((row, index) => (
                  <tr key={index} className="table-row">
                    <td className="table-cell" style={{width:"1%", textAlign:"center"}}>{index}</td>
                    <td className="table-cell" style={{width:"20%"}}>{ConvertDate(row.Timestamp?.Timestamp)}</td>
                    <td className="table-cell" style={{width:"20%"}}>{ConvertDate(row.ReceivedTime?.Timestamp)} <span style={{ float: "right", fontSize:15 }}>{FormatTimeDifference(row?.Timestamp?.Timestamp, row?.ReceivedTime?.Timestamp)}</span></td>
                    <td className="table-cell" style={{width:"45%"}}>{row.DebugSt}</td>
                    <td className="table-cell" style={{width:"35%"}}><span>Lat: {row.Lat}, Lng: {row.Lng}</span> <Button style={{backgroundColor:"#2ECC71",color:"white",float:"right"}} onClick={() => setEventLocationFromMessageLog(row.Lat, row.Lng)}><GpsFixedSharpIcon/></Button></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      ) : ( <p style={{textAlign:"center"}}>No device information available</p> )}
    </div>
  );
};
